import {useTranslation} from "react-i18next";
import {getContrastColor} from "../../../../utils/color";
import {getTrainingSportStringKey, isRowing, TrainingSport} from "../../../../typings/TrainingSport";
import moment from "moment";
import {Card} from "reactstrap";
import styles from "./LiveBoat.module.scss";
import {GlanceIndicatorLive} from "../../GlanceIndicatorLive/GlanceIndicatorLive";
import {getStatIcon, SmallStatCategory} from "../../GlanceStatsSmall/GlanceStatsSmall";
import * as React from "react";
import {ILiveBoat} from "../LiveHome/LiveHome";
import {getLiveShortName} from "../../../../utils/liveHelper";
import {speedToTempo500} from "../../../../utils/unitHelpers";
import Highcharts from "highcharts";

const ManyAthlete = ({boat}: { boat: ILiveBoat }) => {
    const {t} = useTranslation();
    const rowing = boat.sport && isRowing(boat.sport);
    return <div className={styles.manyAthletes}>
        <div className={styles.athletes}>
            <Card className={styles.athlete}>
                <div className={styles.athleteHeader}>{t("common")}</div>
                <hr className={styles.hr}/>
                <div className={styles.glanceCol}>
                    <GlanceIndicatorLive
                        icon={getStatIcon(SmallStatCategory.Distance)}
                        text={t("distance")}
                        value={boat.distance?.toFixed(1)}
                        unit={"km"}
                    />
                    {rowing && <GlanceIndicatorLive
                        icon={getStatIcon(SmallStatCategory.Speed)}
                        text={t("tempo split")}
                        value={(boat.speed && Highcharts.dateFormat("%M:%S", speedToTempo500(boat.speed) * 1000 * 1000)) || "-"}
                        unit={""}
                    />}
                    {!rowing && <GlanceIndicatorLive
                        icon={getStatIcon(SmallStatCategory.Speed)}
                        text={t("speed")}
                        value={boat.speed?.toFixed(1).replace('-0', '0')}
                        unit={"km/h"}
                    />}
                    <GlanceIndicatorLive
                        icon={"/icons/ic_strokes.png"}
                        text={t("stroke rate")}
                        value={boat.strokeRate?.toFixed(1)}
                        unit={"spm"}
                    />
                    <GlanceIndicatorLive
                        icon={"/icons/ic_drive_length.png"}
                        text={t("dps")}
                        value={boat.distancePerStroke.toFixed(1)}
                        unit={"m"}
                    />
                </div>
            </Card>
            {boat.athletes.map((athlete, i) => {
                    const textColor = getContrastColor(athlete.color ?? "#ffffff", "#fff", "#000");
                    return <Card className={styles.athlete} key={i}>
                        <div className={styles.athleteHeader}
                             style={{
                                 backgroundColor: athlete.color,
                                 color: textColor
                             }}>{getLiveShortName(athlete.name)}</div>
                        {!athlete.color && <hr className={styles.hr}/>}
                        <div className={styles.glanceCol}>
                            <GlanceIndicatorLive
                                icon={getStatIcon(SmallStatCategory.HeartRate)}
                                text={t("heartrate")}
                                value={athlete.hr?.toFixed(0).replace('-0', '0')}
                                unit={"bpm"}
                            />
                            <GlanceIndicatorLive
                                icon={getStatIcon(SmallStatCategory.Force)}
                                text={t("pulling force")}
                                value={athlete.force?.toFixed(0).replace('-0', '0')}
                                unit={"N"}
                            />
                            <GlanceIndicatorLive
                                icon={getStatIcon(SmallStatCategory.Force)}
                                text={t("live max pulling force")}
                                value={athlete.maxForce?.toFixed(0).replace('-0', '0')}
                                unit={"N"}
                            />
                        </div>
                    </Card>;
                }
            )}
        </div>
    </div>
}

const SingleAthlete = ({boat}: { boat: ILiveBoat }) => {
    const {t} = useTranslation();
    const rowing = boat.sport && isRowing(boat.sport);

    return <div className={styles.glanceRow}>
        <GlanceIndicatorLive
            icon={getStatIcon(SmallStatCategory.HeartRate)}
            text={t("heartrate")}
            value={boat.athletes[0].hr?.toFixed(0).replace('-0', '0')}
            unit={"bpm"}
        />
        <GlanceIndicatorLive
            icon={getStatIcon(SmallStatCategory.Distance)}
            text={t("distance")}
            value={boat.distance?.toFixed(1)}
            unit={"km"}
        />
        {rowing && <GlanceIndicatorLive
            icon={getStatIcon(SmallStatCategory.Speed)}
            text={t("tempo split")}
            value={(boat.speed && Highcharts.dateFormat("%M:%S", speedToTempo500(boat.speed) * 1000 * 1000)) || "-"}
            unit={""}
        />}
        {!rowing && <GlanceIndicatorLive
            icon={getStatIcon(SmallStatCategory.Speed)}
            text={t("speed")}
            value={boat.speed?.toFixed(1).replace('-0', '0')}
            unit={"km/h"}
        />}
        <GlanceIndicatorLive
            icon={"/icons/ic_strokes.png"}
            text={t("stroke rate")}
            value={boat.strokeRate?.toFixed(1)}
            unit={"spm"}
        />
        <GlanceIndicatorLive
            icon={"/icons/ic_drive_length.png"}
            text={t("dps")}
            value={boat.distancePerStroke.toFixed(1)}
            unit={"m"}
        />
        <GlanceIndicatorLive
            icon={getStatIcon(SmallStatCategory.Force)}
            text={t("pulling force")}
            value={boat.athletes[0].force?.toFixed(0).replace('-0', '0')}
            unit={"N"}
        />
        <GlanceIndicatorLive
            icon={getStatIcon(SmallStatCategory.Force)}
            text={t("live max pulling force")}
            value={boat.athletes[0].maxForce?.toFixed(0).replace('-0', '0')}
            unit={"N"}
        />
    </div>
}

const ExpiredSubscription = () => {
    const {t} = useTranslation();
    return <div className={"position-relative"}>
        <div className={`${styles.glanceRow} ${styles.expired}`}>
            <GlanceIndicatorLive
                icon={getStatIcon(SmallStatCategory.HeartRate)}
                text={t("heartrate")}
                value={"120"}
                unit={"bpm"}
            />
            <GlanceIndicatorLive
                icon={getStatIcon(SmallStatCategory.Distance)}
                text={t("distance")}
                value={"3.1"}
                unit={"km"}
            />
            <GlanceIndicatorLive
                icon={getStatIcon(SmallStatCategory.Speed)}
                text={t("speed")}
                value={"4.1"}
                unit={"km/h"}
            />
            <GlanceIndicatorLive
                icon={"/icons/ic_strokes.png"}
                text={t("stroke rate")}
                value={"20.5"}
                unit={"spm"}
            />
            <GlanceIndicatorLive
                icon={"/icons/ic_drive_length.png"}
                text={t("dps")}
                value={"2.3"}
                unit={"m"}
            />
            <GlanceIndicatorLive
                icon={getStatIcon(SmallStatCategory.Force)}
                text={t("pulling force")}
                value={"20"}
                unit={"N"}
            />
            <GlanceIndicatorLive
                icon={getStatIcon(SmallStatCategory.Force)}
                text={t("live max pulling force")}
                value={"25"}
                unit={"N"}
            />
        </div>
        <div className={`d-flex justify-content-center align-items-center ${styles.expiredMessageContainer}`}>
            <div className={styles.expiredMessage}>
                <span>{t("subscription required")}{' '}</span>
                <a href={"mailto:subscription@thepaddlemate.com"}>subscription@thepaddlemate.com</a>
            </div>
        </div>
    </div>
}

export const LiveBoat = ({boat, selected, onClick}: {
    boat: ILiveBoat;
    selected: boolean;
    onClick: () => void
}) => {
    const {t} = useTranslation();
    const textColor = getContrastColor(boat.color, "#fff", "#000");
    const getNameBySport = (sport: TrainingSport) => {
        return `${t(getTrainingSportStringKey(sport))} ${t("training")}`;
    };
    const createdAt = (boat.createdAt && moment(boat.createdAt)) || null;
    const name = !boat.sport || boat.athletes.length === 1 ? boat.athletes[0].name : getNameBySport(boat.sport);
    let content;
    if (boat.subscriptionExpired)
        content = <ExpiredSubscription/>
    else if (boat.athletes.length === 1)
        content = <SingleAthlete boat={boat}/>
    else
        content = <ManyAthlete boat={boat}/>
    const header = <div className={styles.header} style={{backgroundColor: boat.color}}>
        <span style={{color: textColor}}>{name}</span>
        <div className={styles.activity} style={{color: textColor}}>
            {createdAt && <>
                <div>{t(boat.isActive ? "active" : "last active")}:</div>
                <div>{createdAt.format(createdAt.isSame(moment(), "day") ? "HH:mm:ss" : "YYYY-MM-DD HH:mm")}</div>
            </>}
        </div>
    </div>;
    return <Card
        className={`${styles.boat} ${selected ? styles.selected : ""} 
        ${styles.clickable} ${!boat.isActive ? styles.inactive : ""} 
        ${boat.subscriptionExpired ? styles.subscriptionExpired : ""}`
        }
        onClick={(!boat.subscriptionExpired && onClick) || undefined}>
        {header}
        {content}
    </Card>
}
